import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Layout } from '../templates';
import { SEO } from '../organisms';
import { Intro } from '../molecules';

const NotFoundPage = () => {
  const { dataJson: copy } = useStaticQuery(
    graphql`
      query fourohfour {
        dataJson {
          intro {
            background {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Intro
        title="page not found"
        metaData={{ logoLeft: '404', logoRight: 'not found' }}
        background={copy.intro.background}
        href="/"
      />
    </Layout>
  );
};

export default NotFoundPage;
